import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllTegs,
  getChatQaProjects,
  getChatQaRandom,
  getChatqaResultStatistics,
  getLiveChatAgent,
  getLiveChatAgentId,
  getQaInfoByAgent,
  getQaInfoByAgentChats,
  patchQaChatCheked,
  patchQaChatChekedResult,
} from 'services/API';

export const getLiveChatAgentThunk = createAsyncThunk(
  'livechat/chats',
  async (
    {
      token,
      page,
      selectedColor,
      selectedStatus,
      searchId,
      searchThreadId,
      isAfterDate,
      searchUser,
      isBeforeDate,
      project,
      checked,
    },
    thunkAPI
  ) => {
    try {
      const response = await getLiveChatAgent(
        token,
        page,
        selectedColor,
        selectedStatus,
        searchId,
        searchThreadId,
        isAfterDate,
        searchUser,
        isBeforeDate,
        project,
        checked
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getLiveChatAgentIdThunk = createAsyncThunk(
  'livechat/agent',
  async ({ token, id }, thunkAPI) => {
    try {
      const response = await getLiveChatAgentId(token, id);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const patchQaChatChekedThunk = createAsyncThunk(
  'qa/changeChecked',
  async ({ token, formData, id }, thunkAPI) => {
    try {
      const response = await patchQaChatCheked(token, formData, id);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const patchQaChatChekedResultThunk = createAsyncThunk(
  'qa/changeCheckedResult',
  async ({ token, formData }, thunkAPI) => {
    try {
      const response = await patchQaChatChekedResult(token, formData);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getChatqaResultStatisticsThunk = createAsyncThunk(
  'qa/resultStatistics',
  async ({ token, year, month, reload }, thunkAPI) => {
    try {
      const response = await getChatqaResultStatistics(
        token,
        year,
        month,
        reload
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getAllTegsThunk = createAsyncThunk(
  'qa/allTegs',
  async ({ token, question }, thunkAPI) => {
    try {
      const response = await getAllTegs(token, question);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getQaInfoByAgentThunk = createAsyncThunk(
  'qa/geiInfoByAgent',
  async ({ token, id, created_at_after, created_at_before }, thunkAPI) => {
    try {
      const response = await getQaInfoByAgent(
        token,
        id,
        created_at_after,
        created_at_before
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getQaInfoByAgentChatsThunk = createAsyncThunk(
  'qa/getInfoByAgentChats',
  async (
    { token, id, page, created_at_after, created_at_before },
    thunkAPI
  ) => {
    try {
      const response = await getQaInfoByAgentChats(
        token,
        id,
        page,
        created_at_after,
        created_at_before
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getChatQaRandomThunk = createAsyncThunk(
  'qa/getChatQaRandom',
  async (
    { token, created_at_after, created_at_before },
    thunkAPI
  ) => {
    try {
      const response = await getChatQaRandom(
        token, created_at_after, created_at_before
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);


export const getChatQaProjectsThunk = createAsyncThunk(
  'qa/getChatQaProjects',
  async (
    { token},
    thunkAPI
  ) => {
    try {
      const response = await getChatQaProjects(
        token
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);




