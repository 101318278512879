import GeneralInfo from '../GeneralInfo/GeneralInfo';
import QaMessageArea from '../QaMessageArea/QaMessageArea';
import QaSideBar from '../QaSideBar/QaSideBar';
import QaStatistic from '../QaStatistic/QaStatistic';
import { MainQaConteiner, QaWorkPlaceConteiner } from './QaWorkPlace.styled';
import QaTicketDask from '../QaTicketDask/QaTicketDask';
import { useState } from 'react';

const QaWorkPlace = () => {
  const [comment, setComment] = useState(''); // Стейт для комментария

  return (
    <QaWorkPlaceConteiner>
      <MainQaConteiner>
        {/* <QaSideBar /> */}
        {/* <QaStatistic /> */}
        <QaMessageArea comment={comment} setComment={setComment} />
        <GeneralInfo comment={comment} setComment={setComment}/>
      </MainQaConteiner>
    </QaWorkPlaceConteiner>
  );
};

export default QaWorkPlace;
