import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaymentBotDetSTatPayments, getPaymentBotMonthStat, getPaymentBotStat, getPaymonExportStat, getPaymonExportStatByDayAndParam } from 'services/API';

export const getPaymentBotStatThunk = createAsyncThunk(
  'paymentBot/stat',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymentBotStat(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getPaymentBotDetSTatPaymentsThunk = createAsyncThunk(
  'paymentBot/detSTatPayments',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymentBotDetSTatPayments(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getPaymentBotMonthStatThunk = createAsyncThunk(
  'paymentMonthBot/stat',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymentBotMonthStat(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getPaymonExportStatThunk = createAsyncThunk(
  'paymentExport/stat',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymonExportStat(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
)

export const getPaymonExportStatByDayAndParamThunk = createAsyncThunk(
  'getPaymonExportStatByDayAndParam/stat',
  async ({ token, dateFrom, dateTo, name }, thunkAPI) => {
    try {
      const response = await getPaymonExportStatByDayAndParam(token, dateFrom, dateTo, name);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
)
