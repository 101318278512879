import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  selectAccessToken,
  selectQaChatsInfoAgent,
  selectQaChatsInfoAgentChats,
  selectSwitcher,
} from '../../../redux/selectors';
import { useEffect, useRef, useState } from 'react';
import {
  getQaInfoByAgentChatsThunk,
  getQaInfoByAgentThunk,
} from '../../../redux/qa/qaActions';
import {
  AgentStatInfoContainer,
  AgentStatMainConatiner,
  AgentStatMainText,
  AgentStatMainTextSpan,
  GreenIcon,
  LeftInfoCenterCon,
  LeftInfoCon,
  LeftInfoConSmall,
  LeftInfoConSmallNumber,
  LeftInfoConSmallNumberGreen,
  LeftInfoConSmallText,
  LeftInfoTopCon,
  RightInfoCenterCon,
  RightInfoCon,
  RightInfoTopCon,
  RightInfoTopConItem,
  RightInfoTopConText,
  Table,
  TableContainer,
  Tag,
  Td,
  Th,
  Tr,
  PaginationContainer,
  PaginationButton,
} from './AgentStat.styled';
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts';
import SupportMenu from '../SupportMenu/SupportMenu';

const AgentStat = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const statInfo = useSelector(selectQaChatsInfoAgent);
  const statInfoChats = useSelector(selectQaChatsInfoAgentChats);

  const getFormattedDate = date => {
    return date.toISOString().split('T')[0]; // Формат YYYY-MM-DD
  };

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const [startDate, setStartDate] = useState(getFormattedDate(yesterday));
  const [endDate, setEndDate] = useState(getFormattedDate(today));

  const [currentPage, setCurrentPage] = useState(1); // Добавляем состояние для текущей страницы

  useEffect(() => {
    if (id) {
      dispatch(
        getQaInfoByAgentThunk({
          token,
          id,
          created_at_after: startDate,
          created_at_before: endDate,
        })
      );
      dispatch(
        getQaInfoByAgentChatsThunk({
          token,
          id,
          page: currentPage,
          created_at_after: startDate,
          created_at_before: endDate,
        })
      ); // Передаем текущую страницу в запрос
    }
  }, [id, currentPage, startDate, endDate]); // Добавляем currentPage в зависимости, чтобы запрос менялся при изменении страницы

  const formatDate = isoString => {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = String(date.getUTCFullYear() - 1); // Уменьшаем год на 1, чтобы получить 2024
    return `${day}.${month}.${year}`;
  };

  const formatTimeVerbose = timeString => {
    const [, minutes, seconds] = timeString.split(':');
    return `${parseInt(minutes, 10)} мин ${parseInt(seconds, 10)} сек`;
  };

  // Функция для обработки изменения страницы
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handleStartDateChange = e => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = e => {
    setEndDate(e.target.value);
  };

  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const switcher = useSelector(selectSwitcher);

  const modalRef = useRef(null);

  const calculateFalsePercentage = (trueCount, falseCount) => {
    const totalCount = trueCount + falseCount;
    if (totalCount === 0) {
      return 0;
    }
    const percentage = (falseCount / totalCount) * 100;
    return Math.round(percentage.toFixed(2));
  };

  const toggleModal = userId => {
    setOpenModal(prevState => !prevState);
    setUserId(userId);
  };

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  useEffect(() => {}, [statInfoChats]);

  return (
    <>
      {statInfo?.map(item => (
        <AgentStatMainConatiner key={item?.id}>
          <AgentStatMainText>
            Приветствую вас,{' '}
            <AgentStatMainTextSpan>{item?.agents__name}!</AgentStatMainTextSpan>
          </AgentStatMainText>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            <label>
              С:
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                style={{ marginLeft: '10px' }}
              />
            </label>
            <label>
              По:
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                style={{ marginLeft: '10px' }}
              />
            </label>
          </div>
          <AgentStatInfoContainer>
            {item?.total_chats !== null ? (
              <LeftInfoCon>
                <LeftInfoTopCon>Карта качества</LeftInfoTopCon>
                <LeftInfoCenterCon>
                  <LeftInfoConSmall>
                    <LeftInfoConSmallNumber>
                      {item?.total_chats}
                    </LeftInfoConSmallNumber>
                    <LeftInfoConSmallText>Проверено чатов</LeftInfoConSmallText>
                  </LeftInfoConSmall>
                  <LeftInfoConSmall>
                    <LeftInfoConSmallNumberGreen>
                      {item?.grade}
                    </LeftInfoConSmallNumberGreen>
                    <LeftInfoConSmallText>Средняя оценка</LeftInfoConSmallText>
                  </LeftInfoConSmall>
                  <LeftInfoConSmall>
                    <LeftInfoConSmallNumber>
                      {item?.total_issues}
                    </LeftInfoConSmallNumber>
                    <LeftInfoConSmallText>Найдено ошибок</LeftInfoConSmallText>
                  </LeftInfoConSmall>
                </LeftInfoCenterCon>
              </LeftInfoCon>
            ) : (
              <></>
            )}

            {item?.top_issues ? (
              <>
                {' '}
                <PieChart width={250} height={250}>
                  <Pie
                    data={item?.top_issues}
                    dataKey="count"
                    nameKey="tag"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    style={{ outline: 'none' }}
                  >
                    {item?.top_issues.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} />
                    ))}
                    <Label position="center" content={() => 'Топ проблемы'} />
                  </Pie>
                  <Tooltip formatter={(value, name) => [value, name]} />
                </PieChart>
                <RightInfoCon>
                  <RightInfoTopCon>Топ 5 ошибок</RightInfoTopCon>
                  <RightInfoCenterCon>
                    {item?.top_issues?.map((item, index) => (
                      <RightInfoTopConItem key={index}>
                        <GreenIcon style={{ background: item?.color }} />
                        <RightInfoTopConText>{item.tag}</RightInfoTopConText>
                      </RightInfoTopConItem>
                    ))}
                  </RightInfoCenterCon>
                </RightInfoCon>
              </>
            ) : (
              <></>
            )}
          </AgentStatInfoContainer>
          {console.log(statInfo[0])}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '10px',
              marginTop:"50px"
            }}
          >
            {statInfo[0]?.violated_questions?.map(
              ({ question_id, question_text, violations }) => (
                <div
                  key={question_id}
                  style={{
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    background: '#fff',
                    border: "2px solid #ae8cff"
                  }}
                >
                  {question_text}: <strong>{violations}</strong>
                </div>
              )
            )}
          </div>

          {statInfoChats?.results.length !== 0 ? (
            <>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <Th>Дата</Th>
                      <Th>Неделя</Th>
                      <Th>Тег ошибок</Th>
                      <Th>Длина</Th>
                      <Th>Балл</Th>
                      <Th>Комментарий</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {statInfoChats?.results.map((row, index) => (
                      <Tr
                        key={index}
                        onClick={() => {
                          toggleModal(row?.pk);
                        }}
                      >
                        <Td>{formatDate(row.created_at)}</Td>
                        <Td>{row.week} неделя</Td>
                        <Td
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          {row?.tags.map((tag, i) => (
                            <Tag key={i}>{tag}</Tag>
                          ))}
                        </Td>
                        <Td>{formatTimeVerbose(row?.chat_duration)}</Td>
                        <Td>{row?.score}</Td>
                        <Td>{row?.comment}</Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              {/* Добавляем пагинацию */}

              <PaginationContainer>
                {statInfoChats?.previous !== null ? (
                  <PaginationButton
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {'<'}
                  </PaginationButton>
                ) : (
                  <></>
                )}
                {statInfoChats?.next !== null ? (
                  <PaginationButton
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    {'>'}
                  </PaginationButton>
                ) : (
                  <></>
                )}
              </PaginationContainer>
            </>
          ) : (
            <></>
          )}
        </AgentStatMainConatiner>
      ))}
      {openModal && (
        <div ref={modalRef}>
          <SupportMenu
            switcher={switcher}
            userId={userId}
            chats={statInfoChats?.results}
            calculateFalsePercentage={calculateFalsePercentage}
          />
        </div>
      )}
    </>
  );
};

export default AgentStat;
