import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Header/Header';
import SideBar from './SideBar/SideBar';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Load } from './App.styled';
import PrivateRoute from './Guard/PrivateRoute';
import PublicRoute from './Guard/PublicRoute';
import { useAuth } from 'hooks/useAuth';
import FrodPage from 'pages/FrodPage/FrodPage';
import { useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectUserConfig,
  selectUserDepartment,
} from '../redux/selectors';
import { useDispatch } from 'react-redux';
import { getCurrentUserTokenThunk } from '../redux/user/userActions';

import { MutatingDots } from 'react-loader-spinner';
import BugReport from './BugReport/BugReport';

import { webOpen, webUpdate } from 'services/API';
import {
  setConfig,
  setRequest,
  setShift,
  setUser,
} from '../redux/user/userSlice';
import { setTable } from '../redux/schedule/scheduleSlice';
import DataBasePage from '../pages/DataBasePage/DataBasePage';
import HomeDBPage from 'pages/DataBasePage/HomeDB/HomeDB';
import SpacesDBPage from 'pages/DataBasePage/SpacesDB/SpacesDB';
import RecentDBPage from 'pages/DataBasePage/RecentDB/RecentDB';
import CreateNewDBPage from 'pages/DataBasePage/CreateNewDB/CreateNewDB';
import ProjectPagePage from 'pages/DataBasePage/ProjectPage/ProjectPagePage';
import StatisticAgent from './Statistics/StatisticAgent/StatisticAgent';
import QaPage from 'pages/QaPage/QaPage';
import StatisticsSuper from './Statistics/StatisticSuper/StatisticsSuper';
import QaArchivePage from 'pages/QaArchivePage/QaArchivePage';
import TicketChatPage from '../pages/TicketChatPage/TicketChatPage';
import { postScheduleWorkerThunk } from '../redux/schedule/scheduleOperations';
import { socket } from 'services/API';
import { resetTimer, stopTimer } from '../redux/timer/timerSlice';
import AgentStat from './QaPage/AgentStat/AgentStat';
// import AgentStat from './QaPage/AgentStat/AgentStat.jsx';

const PersonalSchedulePage = lazy(() =>
  import('pages/PersonalSchedulePage/PersonalSchedulePage')
);
const SchedulePage = lazy(() => import('pages/SchedulePage/SchedulePage'));
const NewsPage = lazy(() => import('pages/NewsPage/NewsPage'));
const ToolsTasksPage = lazy(() =>
  import('pages/ToolsTasksPage/ToolsTasksPage')
);
const UserPage = lazy(() => import('pages/User/UserPage/UserPage'));
const UserPasswordPage = lazy(() =>
  import('pages/User/UserPassword/UserPasswordPage')
);
const UserSalaryPage = lazy(() =>
  import('pages/User/UserSalary/UserSalaryPage')
);
const CurrencyPage = lazy(() => import('pages/CurrencyPage/CurrencyPage'));
const AuthPage = lazy(() => import('pages/AuthPage/AuthPage'));
const StatisticsPage = lazy(() =>
  import('pages/StatisticsPage/StatisticsPage')
);
const ArchivePage = lazy(() => import('pages/ArchivePage/ArchivePage'));
const VipemailsPage = lazy(() => import('pages/VipemailsPage/VipemailsPage'));
const SupportVip = lazy(() => import('./QaPage/SupportVip/SupportVip'));
const PaymentBotStat = lazy(() => import('./PaymentBotStat/PaymentBotStat'));
const PaymentBotMonthStat = lazy(() =>
  import('./PaymentBotMonthStat/PaymentBotMonthStat')
);
const QaAgentsStatistic = lazy(() =>
  import('./QaPage/QaAgentsStatistic/QaAgentsStatistic')
);
// const AgentStat = lazy(() => import('./QaPage/AgentStat/AgentStat'));

export const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isRefreshing } = useAuth();
  const dep = useSelector(selectUserDepartment);
  const token = useSelector(selectAccessToken);
  const config = useSelector(selectUserConfig);
  const [changePage, setChangePage] = useState(true);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [searchId, setSearchId] = useState('');
  const [searchThreadId, setSearchThreadId] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [searchChecked, setSearchChecked] = useState();
  const [isAfterDate, setIsAfterDate] = useState(false);
  const [isBeforeDate, setIsBeforeDate] = useState(false);
  const [clickModal, setClickModal] = useState(false);
  const [DeleteClick, setDeleteClick] = useState(false);

  const [selectedValues, setSelectedValues] = useState({
    selectedProject: null,
    selectedShift: null,
  });

  useEffect(() => {
    setSelectedValues({
      selectedProject: config?.project,
      selectedShift: config?.shift_type,
    });
  }, [config]);

  useEffect(() => {
    const unload = () => {
      if (config?.shift_type === 'requests') {
        dispatch(
          postScheduleWorkerThunk({
            token,
            scheduleData: { action: 'stop', value: true },
          })
        ).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            socket.send(
              JSON.stringify({
                action: 'unsubscribe',
                payload: {
                  channel: 'antifraud_requests',
                  project: config?.project,
                },
              })
            );
          }
        });
      } else if (config?.shift_type && config?.shift_type !== 'requests') {
        dispatch(
          postScheduleWorkerThunk({
            token,
            scheduleData: { action: 'stop', value: true },
          })
        ).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(stopTimer());
            dispatch(resetTimer());
          }
        });
      }
    };

    window.addEventListener('unload', unload);

    return () => {
      window.removeEventListener('unload', unload);
    };
  }, [config, token]);

  // const handleUpdateSelectedValues = (project, shift) => {
  //   setSelectedValues({
  //     selectedProject: project,
  //     selectedShift: shift,
  //   });
  // };

  // socket.onclose = () => {
  //   console.log('WebSocket соединение закрыто');
  // };

  // socket.onerror = error => {
  //   console.error('WebSocket ошибка:', error);
  // };

  // if (socket.readyState === WebSocket.OPEN) {
  //   const unsubscribeMessage = JSON.stringify({
  //     action: 'unsubscribe',
  //     payload: {
  //       channel: 'schedule',
  //       schedule_group: 1,
  //     },
  //   });

  //   socket.send(unsubscribeMessage);
  //   socket.close();
  // }

  // return () => {

  // };

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUserTokenThunk(token));
    }
  }, []);

  useEffect(() => {
    if (token) {
      webOpen(token);
      // Передаем колбэк для обновления данных внутри компонента
      webUpdate(data => {
        if (data.action === 'schedule.modified') {
          dispatch(setTable(data.payload.table));
        }
        if (data.action === 'shift.modified') {
          dispatch(setUser(data.payload));
        }
        if (data.action === 'config.modified') {
          dispatch(setConfig(data.payload));
        }
        if (data.action === 'antifraud.requests') {
          dispatch(setRequest(data.payload));
        }
        if (data.action === 'agents_working.updated') {
          dispatch(setShift(data.payload));
        }
      });
    }
  }, [token]);

  return isRefreshing ? (
    <b>Refreshing user...</b>
  ) : (
    <>
      {location.pathname !== '/login' && (
        <>
          <Header
            setSelectedColor={setSelectedColor}
            setSelectedStatus={setSelectedStatus}
            setSelectedProject={setSelectedProject}
            setSearchId={setSearchId}
            setSearchThreadId={setSearchThreadId}
            searchChecked={searchChecked}
            setSearchChecked={setSearchChecked}
            searchId={searchId}
            searchThreadId={searchThreadId}
            selectedColor={selectedColor}
            selectedStatus={selectedStatus}
            selectedProject={selectedProject}
            setIsAfterDate={setIsAfterDate}
            setIsBeforeDate={setIsBeforeDate}
            setSearchUser={setSearchUser}
            searchUser={searchUser}
            setClickModal={setClickModal}
            clickModal={clickModal}
            setDeleteClick={setDeleteClick}
            isAfterDate={isAfterDate}
            isBeforeDate={isBeforeDate}
          />
          {changePage && <SideBar setChangePage={setChangePage} />}
          <BugReport />
        </>
      )}

      <Suspense
        fallback={
          <Load>
            <MutatingDots
              height="100"
              width="100"
              color="#AFFFB7"
              secondaryColor="#AFFFB7"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </Load>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <PersonalSchedulePage />
              </PrivateRoute>
            }
          />
          {dep === 'Qa' && (
            <>
              <Route
                path="/qa"
                element={
                  <PrivateRoute>
                    <SupportVip
                      selectedColor={selectedColor}
                      selectedStatus={selectedStatus}
                      searchId={searchId}
                      searchThreadId={searchThreadId}
                      searchUser={searchUser}
                      clickModal={clickModal}
                      isAfterDate={isAfterDate}
                      isBeforeDate={isBeforeDate}
                      DeleteClick={DeleteClick}
                      selectedProject={selectedProject}
                      searchChecked={searchChecked}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/qaStatistic"
                element={
                  <PrivateRoute>
                    <QaAgentsStatistic />
                  </PrivateRoute>
                }
              />
              <Route path="/userStat/:id" element={<AgentStat />} />
            </>
          )}
          {dep !== 'Supports' && (
            <>
              <Route
                path="/fraudPage"
                element={
                  <PrivateRoute>
                    <FrodPage selectedValues={selectedValues} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/statistics"
                element={
                  <PrivateRoute>
                    <StatisticsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/statistics/:agent/:date"
                element={
                  <PrivateRoute>
                    <StatisticAgent />
                  </PrivateRoute>
                }
              />
              <Route
                path="/statistics/project/:project/:date"
                element={
                  <PrivateRoute>
                    <StatisticsSuper />
                  </PrivateRoute>
                }
              />
              <Route
                path="/archive"
                element={
                  <PrivateRoute>
                    <ArchivePage />
                  </PrivateRoute>
                }
              />
            </>
          )}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <AuthPage />
              </PublicRoute>
            }
          />
          <Route
            path="/schedule"
            element={
              <PrivateRoute>
                <SchedulePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/VipEmails"
            element={
              <PrivateRoute>
                <VipemailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/news"
            element={
              <PrivateRoute>
                <NewsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <PrivateRoute>
                <ToolsTasksPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <UserPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/userPasswordPage"
            element={
              <PrivateRoute>
                <UserPasswordPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/userSalaryPage"
            element={
              <PrivateRoute>
                <UserSalaryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/cashback"
            element={
              <PrivateRoute>
                <CurrencyPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/PaymentBot"
            element={
              <PrivateRoute>
                <PaymentBotStat
                  isAfterDate={isAfterDate}
                  isBeforeDate={isBeforeDate}
                  // clickModal={clickModal}
                  // DeleteClick={DeleteClick}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/PaymentMonthStat"
            element={
              <PrivateRoute>
                <PaymentBotMonthStat />
              </PrivateRoute>
            }
          />
          <Route
            path="/database/:id"
            element={
              <PrivateRoute>
                <DataBasePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/homeDataBase"
            element={
              <PrivateRoute>
                <HomeDBPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/spacesDataBase"
            element={
              <PrivateRoute>
                <SpacesDBPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/recentDataBase"
            element={
              <PrivateRoute>
                <RecentDBPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/createDataBase"
            element={
              <PrivateRoute>
                <CreateNewDBPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectPage/:id"
            element={
              <PrivateRoute>
                <ProjectPagePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/QaPage"
            element={
              <PrivateRoute>
                <QaPage setChangePage={setChangePage} />
              </PrivateRoute>
            }
          />
          <Route
            path="/QaArchive"
            element={
              <PrivateRoute>
                <QaArchivePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/GHTIX"
            element={
              <PrivateRoute>
                <TicketChatPage />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </>
  );
};
