import {
  AgentMessage,
  ChatConteiner,
  ChatInfo,
  Container,
  CustomerMessage,
  DateInput,
  GetChatButton,
  ImageContainer,
  InputContainer,
  InputValueBlock,
  MainCont,
  MessageConatiner,
  MessageContainerInfo,
  MessageConteiner,
  MessageConteinerItem,
  QaMessageAreaConteiner,
  SendButton,
  Shield,
  SystemMessage,
  TagBlock,
  TagButton,
  Telegram,
  TextAreaMainContainer,
  TextAreaQa,
  TextAreaToolsContainer,
  TextAreaToolsConteiner,
  TextAreaWrapper,
  TimeChat,
  UserChatImg,
  UserChatName,
  ValueButton,
  ValueButtonImg,
  ValueInput,
} from './QaMessageArea.styled';
import imgTest from '../../../images/userPhoto.png';
import Arrow from '../../../images/Arrow.png';
import Pensil from '../../../images/Pensil.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectQaChatsRandomInfo,
} from '../../../redux/selectors';
import { getChatQaRandomThunk } from '../../../redux/qa/qaActions';
import { DateInfo } from '../GeneralInfo/GeneralInfo.styled';
import { Notify } from 'notiflix';

const QaMessageArea = ({ comment, setComment }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const chatInfo = useSelector(selectQaChatsRandomInfo);

  const [isDisabled, setIsDisabled] = useState(true); // Управление состоянием

  const toggleDisabled = () => {
    setIsDisabled(prevState => !prevState); // Меняем значение на противоположное
  };

  const handleGetChat = async () => {
    const response = await dispatch(
      getChatQaRandomThunk({
        token,
        created_at_after: startDate,
        created_at_before: endDate,
      })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      Notify.success('✅ Чат получен');
    } else {
      Notify.failure('Чатов нет');
    }
  };

  useEffect(() => {
    console.log(chatInfo);
  }, [chatInfo]);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <QaMessageAreaConteiner>
      <ChatConteiner>
        {chatInfo !== null ? (
          <>
            {(startDate || endDate) && (
              <DateInfo>
                Фильтр: {startDate && `с ${startDate}`}{' '}
                {endDate && `до ${endDate}`}
              </DateInfo>
            )}
            {chatInfo?.messages.map((item, index) => (
              <div key={index}>
                {console.log(item)}{' '}
                {item.author.type === 'system' && (
                  <SystemMessage>{item.text} {formatTime(item?.created_at)}</SystemMessage>
                )}
                {item.author.type === 'agent' && (
                  <MainCont side={'right'}>
                    <MessageConatiner side={'right'}>
                      <MessageContainerInfo side={'right'}>
                        <TimeChat side={'right'}>{formatTime(item?.created_at)}</TimeChat>
                        <UserChatName>{item.author.name}</UserChatName>
                      </MessageContainerInfo>
                      <AgentMessage>{item.text}</AgentMessage>
                    </MessageConatiner>
                    <ImageContainer>
                      <UserChatImg side={'right'} src={imgTest} />
                    </ImageContainer>
                  </MainCont>
                )}
                {item.author.type === 'customer' && (
                  <MainCont side={'left'}>
                    <ImageContainer>
                      <UserChatImg side={'left'} src={imgTest} />
                    </ImageContainer>
                    <MessageConatiner side={'left'}>
                      <MessageContainerInfo side={'left'}>
                        <UserChatName>{item.author.name}</UserChatName>
                        <TimeChat side={'left'}>{formatTime(item?.created_at)}</TimeChat>
                      </MessageContainerInfo>
                      <CustomerMessage>{item.text}</CustomerMessage>
                    </MessageConatiner>
                  </MainCont>
                )}
              </div>
            ))}
            <TextAreaMainContainer>
              <TextAreaWrapper>
                {/* <InputValueBlock>
                  <InputContainer>
                    <ValueInput type="text" disabled={isDisabled} />
                    <ValueButton onClick={toggleDisabled}>
                      <img src={Pensil} alt="Pensil" />
                    </ValueButton>
                  </InputContainer>
                </InputValueBlock> */}
                <TextAreaQa
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  placeholder="Введите комментарий..."
                />

                {/* <TextAreaToolsContainer>
                  <TagBlock>
                    <TagButton>
                      <Shield />
                      Add tag
                    </TagButton>
                  </TagBlock>
                  <SendButton>
                    Send
                    <Telegram src={Arrow} alt="Arrow" />
                  </SendButton>
                </TextAreaToolsContainer> */}
              </TextAreaWrapper>
            </TextAreaMainContainer>
          </>
        ) : (
          <>
            <GetChatButton onClick={() => handleGetChat()}>
              Получить Чат
            </GetChatButton>
            <Container>
              <div>
                С:{' '}
                <DateInput
                  type="date"
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                />
              </div>
              <div>
                По:{' '}
                <DateInput
                  type="date"
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
              </div>
            </Container>
          </>
        )}
      </ChatConteiner>
    </QaMessageAreaConteiner>
  );
};

export default QaMessageArea;
