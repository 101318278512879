import {
  AnalyticsBlock,
  AnalyticsItem,
  AnalyticsList,
  AnalyticsMainText,
  CommentText,
  CorrectItem,
  IdInformation,
  MainText,
  Minus,
  MistakeItem,
  PercentBarBlock,
  PercentItem,
  Plus,
  Progress,
  ProgressBar,
  ResultsBlock,
  SupportMenuConteiner,
  TextBlock,
  TextInformationBlock,
  User,
  UserBlock,
  UserGradeBlock,
  UserImg,
  UserJobTitle,
  UserName,
  SwitchBar,
  SwitchButton,
  ChatConteiner,
  SystemMessage,
  AgentMessage,
  CustomerMessage,
  ChatInfo,
  UserChatImg,
  UserChatName,
  TimeChat,
  MessageConteiner,
  CreateAtTitle,
  CheckedBox,
  Cross,
  CheckedButton,
  ResetIcon,
  DoneIcon,
  DecisionButton,
  DicisionIcon,
  ModalOverlay,
  ModalContent,
  CloseButton,
  TextArea,
  Input,
  SubmitButton,
  FormField,
  CheckboxWrapper,
  Labelll,
  FormFieldd,
  AnalyticsItemConatiner,
  CommentTextItem,
  List,
  Item,
  CommentTextItemTags,
} from './SupportMenu.styled';
import imgTest from '../../../images/userPhoto.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllTegsThunk,
  getLiveChatAgentIdThunk,
  patchQaChatChekedResultThunk,
  patchQaChatChekedThunk,
} from '../../../redux/qa/qaActions';
import {
  selectAccessToken,
  selectQaChatsAgent,
  selectQaChatsAllTegs,
} from '../../../redux/selectors';
import { Notify } from 'notiflix';

const SupportMenu = ({ userId, chats, switcher }) => {
  const [openTextIndex, setOpenTextIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('analytics');
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const agent = useSelector(selectQaChatsAgent);
  const allTegs = useSelector(selectQaChatsAllTegs);
  const [activeUser, setActiveUser] = useState(null); // Храним активного пользователя
  const [activeUserResault, setActiveUserResault] = useState(null);
  const currentChat = chats.filter(item => item.pk === userId);
  useEffect(() => {
    if (Array.isArray(agent?.username) && agent?.username.length > 0) {
      // Если активный пользователь ещё не выбран, выбираем первого
      setActiveUser(prevUser => prevUser || agent.username[0]);
    }
  }, [agent?.username, userId]);

  const toggleText = index => {
    setOpenTextIndex(prevIndex => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (activeUser) {
      setActiveUserResault(agent?.results);
    }
  }, [activeUser, agent?.results]);

  useEffect(() => {
    dispatch(getLiveChatAgentIdThunk({ token, id: userId }));
  }, [userId]);

  function countDecisions(results) {
    if (!results || typeof results !== 'object') {
      console.error('Invalid results object:', results);
      return [];
    }

    return Object.entries(results).map(([user, decisions]) => {
      if (!Array.isArray(decisions)) {
        console.error(`Invalid decisions array for user ${user}:`, decisions);
        return { user, trueCount: 0, falseCount: 0 };
      }

      const counts = decisions.reduce(
        (acc, item) => {
          if (item.decision === true) {
            acc.trueCount += 1;
          } else if (item.decision === false) {
            acc.falseCount += 1;
          }
          return acc;
        },
        { trueCount: 0, falseCount: 0 } // Начальное значение
      );

      return { user, ...counts };
    });
  }

  const convertToHHMMSS = isoTime => {
    const date = new Date(isoTime);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const formatDate = isoDate => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    // Преобразование ISO в объект Date
    const date = new Date(isoDate);

    // Форматирование в читабельный вид
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    if (activeUser) {
      setActiveUserResault(agent?.results);
    }
  }, [activeUser, agent?.results]);

  const [rep, setRep] = useState();

  const countAgentMessages = messages => {
    return messages?.reduce((acc, message) => {
      if (message.author.type !== 'system') {
        const author = message.author.name;
        acc[author] = (acc[author] || 0) + 1;
      }

      return acc;
    }, {});
  };

  useEffect(() => {
    if (agent?.messages) {
      const countedMessages = countAgentMessages(agent.messages);
      setRep(countedMessages);
    }
  }, [agent]); // Добавляем зависимость agent

  const comparisonDate = new Date('2025-02-18T08:00:00+01:00');

  const ChangeChecked = async () => {
    const need = {
      checked: !agent?.checked,
    };

    const response = await dispatch(
      patchQaChatChekedThunk({ token, formData: need, id: agent?.pk })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      console.log('ok');
    }
  };

  const [modalData, setModalData] = useState(null);
  const [modalDataAgent, setModalDataAgent] = useState(null);

  const openModal = (item, agentSafe) => {
    setModalData(item);
    setModalDataAgent(agentSafe);
    dispatch(getAllTegsThunk({ token, question: item?.question }));
  };

  const closeModal = () => {
    setModalData(null);
  };

  const countAllDecisions = data => {
    if (!data || typeof data !== 'object')
      return { trueCount: 0, falseCount: 0 };

    return Object.values(data).reduce(
      (acc, user) => {
        user?.results?.forEach(item => {
          if (item.decision) {
            acc.trueCount += 1;
          } else {
            acc.falseCount += 1;
          }
        });
        return acc;
      },
      { trueCount: 0, falseCount: 0 }
    );
  };

  useEffect(() => {}, [modalData]);

  const Modal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState();

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
      setFormData({
        decision: modalData?.decision || false,
        manager_comment: modalData?.manager_comment || '',
        tags: modalData?.tags || [], // Теги, если decision true
      });
    }, []);

    useEffect(() => {}, [formData]);

    if (!isOpen) return null;

    // Обработчик изменения checkbox и тегов
    const handleChange = (e, error = null) => {
      const { name, type, checked, value } = e.target;

      // Логируем изменения

      // Если изменяется decision checkbox
      if (name === 'decision') {
        setFormData(prev => {
          return {
            ...prev,
            decision: checked,
            tags: checked ? prev.tags : [], // если decision true, то очищаем теги
          };
        });
      }

      // Если меняется тег (если decision false)
      if (error) {
        // Убираем проверку на name, поскольку для тегов передается error
        setFormData(prev => {
          const isTagSelected = prev.tags.some(tag => tag.id === error.id); // Проверка, выбран ли уже тег

          return {
            ...prev,
            tags: isTagSelected
              ? prev.tags.filter(tag => tag.id !== error.id) // Если выбран, удаляем тег
              : [...prev.tags, error], // Если не выбран, добавляем тег
          };
        });
        setSearchQuery('');
      }

      // Обновляем другие данные в форме (например, комментарий)
      if (name && name !== 'decision' && name !== 'tags') {
        setFormData(prev => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value,
        }));
      }
    };

    // Обработчик отправки формы
    const handleSubmit = async e => {
      e.preventDefault();

      try {
        if (!modalData?.pk) {
          console.error('Ошибка: нет ID у modalData');
          return;
        }

        // Формируем правильную структуру данных
        const payload = [
          {
            id: modalData.pk, // ID чата
            decision: formData.decision,
            manager_comment: formData.manager_comment,
            tags: formData.decision
              ? [] // если decision = true, теги очищаются
              : formData.tags.map(tag => tag.id), // отправляем только ID тегов
          },
        ];

        const response = await dispatch(
          patchQaChatChekedResultThunk({
            token,
            formData: payload,
          })
        );

        if (response.meta.requestStatus === 'fulfilled') {
          console.log('Данные успешно обновлены');

          await dispatch(getLiveChatAgentIdThunk({ token, id: userId }));

          setModalData(null);
          onClose();
        }
      } catch (error) {
        console.error('Ошибка при обновлении данных:', error);
      }
    };

    // Фильтрация тегов на основе введенного текста
    const filteredTags = allTegs?.filter(
      tag => tag.name.toLowerCase().includes(searchQuery.toLowerCase()) // игнорируем регистр
    );

    const handleSearchChange = e => {
      setSearchQuery(e.target.value);
    };

    // console.log(countDecisions())

    return (
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={e => e.stopPropagation()}>
          <CloseButton onClick={onClose}>✖</CloseButton>
          <h2>{modalData?.question}</h2>
          <form onSubmit={handleSubmit}>
            <FormFieldd>
              <Labelll>Решение:</Labelll>
              <CheckboxWrapper>
                <Input
                  type="checkbox"
                  name="decision"
                  checked={formData?.decision}
                  onChange={handleChange}
                />
              </CheckboxWrapper>
            </FormFieldd>

            <FormField>
              <Labelll>Комментарий:</Labelll>
              <TextArea
                name="manager_comment"
                value={formData?.manager_comment}
                onChange={handleChange}
                placeholder="Enter your comment..."
              />
            </FormField>

            <FormField>
              {!formData?.decision && (
                <>
                  <Labelll>
                    Теги:{' '}
                    {formData?.tags.map(tag => {
                      return <>{tag?.name}, </>;
                    })}
                  </Labelll>
                  <h3>
                    Поиск тегов{' '}
                    <input
                      type="text"
                      placeholder="Search tags..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </h3>
                  <List>
                    {/* Поле ввода для поиска */}

                    {/* Отображаем только отфильтрованные теги */}
                    {filteredTags?.map(error => (
                      <Item
                        key={error.id}
                        selected={formData?.tags.some(
                          tag => tag.id === error.id
                        )}
                        onClick={e => handleChange(e, error)} // передаем error для toggleSelection
                      >
                        {error.name}
                      </Item>
                    ))}
                  </List>
                </>
              )}
            </FormField>

            <SubmitButton type="submit">Сохранить</SubmitButton>
          </form>
        </ModalContent>
      </ModalOverlay>
    );
  };

  // Устанавливаем активного пользователя по умолчанию при наличии агентов
  useEffect(() => {
    if (Array.isArray(agent?.username) && agent.username.length > 0) {
      setActiveUser(agent.username[0]); // Если агентов больше одного, выбираем первого
    }
  }, [agent?.username]);

  return (
    <SupportMenuConteiner switcher={switcher}>
      <TextInformationBlock>
        <IdInformation>{agent?.thread_id}</IdInformation>
      </TextInformationBlock>
      <UserGradeBlock>
        <User>
          {agent?.photo === null ? (
            <UserImg src={imgTest} />
          ) : (
            <UserImg src={agent?.photo} />
          )}
          <UserBlock>
            <UserJobTitle>Имя агента</UserJobTitle>
            {agent?.username === null ? (
              <UserName switcher={switcher}>Артём Ковальцев</UserName>
            ) : (
              <UserName switcher={switcher}>
                {Array.isArray(agent?.username)
                  ? agent?.username.join(', ')
                  : agent?.username}
              </UserName>
            )}
          </UserBlock>
        </User>
        <PercentBarBlock>
          <PercentItem>Оценка звонка {agent?.score}</PercentItem>
          <ProgressBar>
            <Progress percent={agent?.score} />
          </ProgressBar>
        </PercentBarBlock>
        <ResultsBlock>
          <MistakeItem>
            Ошибок: {countAllDecisions(agent?.results)?.falseCount}
          </MistakeItem>
          <CorrectItem>
            Корректно: {countAllDecisions(agent?.results)?.trueCount}
          </CorrectItem>
        </ResultsBlock>
        <TextInformationBlock>
          <IdInformation>{agent?.thread_id}</IdInformation>
        </TextInformationBlock>
      </UserGradeBlock>

      <SwitchBar>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <SwitchButton
            active={activeTab === 'analytics'}
            onClick={() => setActiveTab('analytics')}
          >
            Аналитика
          </SwitchButton>
          <SwitchButton
            active={activeTab === 'chat'}
            onClick={() => setActiveTab('chat')}
          >
            Чат
          </SwitchButton>
          <CheckedBox>
            Проврка чата: {agent?.checked ? <DoneIcon /> : <Cross />}
            <CheckedButton onClick={() => ChangeChecked()}>
              <ResetIcon />
            </CheckedButton>
          </CheckedBox>
        </div>
        {new Date(agent?.created_at) < comparisonDate ? (
          <>
            <h3>
              Старая версия чата, анализ проводился для всего чата в целом
            </h3>
          </>
        ) : (
          <></>
        )}
      </SwitchBar>
      {activeTab === 'analytics' && (
        <AnalyticsBlock>
          <AnalyticsMainText switcher={switcher}>Аналитика</AnalyticsMainText>
          <AnalyticsList>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
              {Array.isArray(agent?.username) && agent.username.length > 0 ? (
                agent?.username.map((user, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setActiveUser(user);
                    }}
                  >
                    <strong style={{ cursor: 'pointer' }}>{user}</strong>
                  </div>
                ))
              ) : (
                <div>Агент не найден</div> // Добавляем сообщение на случай отсутствия пользователей
              )}
            </div>
            {/* Анализ для активного оператора */}
            {activeUser ? (
              <div>
                <h3 style={{ margin: '5px 0' }}>Анализ для {activeUser}</h3>
                <PercentItem>
                  Оценка звонка {activeUserResault?.[activeUser]?.score}%
                </PercentItem>
                {/* {Object.entries(activeUserResault).map(([author, count]) => (
                  <li key={author} className="text-gray-700">
                    {author}: {count}
                  </li>
                ))} */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <ProgressBar>
                    <Progress
                      percent={activeUserResault?.[activeUser]?.score}
                    />
                  </ProgressBar>
                  {rep ? (
                    <h4 style={{ margin: '5px 0' }}>
                      Количество реплик:{' '}
                      {Object.values(rep).reduce(
                        (acc, value) => acc + value,
                        0
                      )}
                    </h4>
                  ) : (
                    <></>
                  )}
                </div>
                {console.log(agent?.results?.[activeUser])}
                {Array.isArray(agent?.results?.[activeUser]?.results) ? (
                  agent.results[activeUser]?.results.map((item, index) => (
                    <AnalyticsItemConatiner key={index}>
                      <AnalyticsItem
                        onClick={() => toggleText(index)}
                        switcher={switcher}
                      >
                        <TextBlock>
                          {item.question}
                          {openTextIndex === index && (
                            <CommentText>{item.explanation}</CommentText>
                          )}
                        </TextBlock>
                        {item.decision === true ? <Plus /> : <Minus />}
                      </AnalyticsItem>
                      <p style={{ margin: '0', width: '60px' }}>
                        Проверка агентом:{' '}
                      </p>
                      {item?.checked ? <DoneIcon /> : <Cross />}
                      <DecisionButton onClick={() => openModal(item, agent)}>
                        <DicisionIcon />
                      </DecisionButton>
                      {item?.manager_comment !== null ? (
                        <>
                          <CommentText>Комментарий:</CommentText>
                          <TextBlock>
                            <CommentTextItem>
                              {item?.manager_comment}
                            </CommentTextItem>
                          </TextBlock>
                        </>
                      ) : (
                        <></>
                      )}
                      {item?.tags.length > 0 ? (
                        <>
                          <CommentText>Теги:</CommentText>
                          <CommentTextItemTags>
                            {item?.tags.map(tag => tag?.name).join(', ')}
                          </CommentTextItemTags>
                        </>
                      ) : (
                        <></>
                      )}
                    </AnalyticsItemConatiner>
                  ))
                ) : (
                  <p>No results found for {activeUser}</p> // Если данных нет, отображаем сообщение
                )}

                <Modal
                  isOpen={modalData !== null}
                  modalDataAgent={modalDataAgent}
                  onClose={closeModal}
                />

                {/* Модальное окно с передачей pk */}
              </div>
            ) : (
              <></>
            )}
          </AnalyticsList>
        </AnalyticsBlock>
      )}
      {activeTab === 'chat' && (
        <>
          <AnalyticsMainText>Chat</AnalyticsMainText>
          <ChatConteiner>
            <CreateAtTitle>
              Started - {formatDate(agent.created_at)}
            </CreateAtTitle>
            {agent?.messages.map((item, index) => (
              <div key={index}>
                {item.author.type === 'system' && (
                  <SystemMessage>
                    {item.text} {convertToHHMMSS(item.created_at)}{' '}
                  </SystemMessage>
                )}
                {item.author.type === 'agent' && (
                  <>
                    <ChatInfo side={'right'}>
                      <TimeChat side={'right'}>
                        {convertToHHMMSS(item.created_at)}
                      </TimeChat>
                      <UserChatName switcher={switcher}>
                        {item.author.name}
                      </UserChatName>
                      <UserChatImg side={'right'} src={imgTest} />
                    </ChatInfo>
                    <MessageConteiner side={'right'}>
                      <AgentMessage>{item.text}</AgentMessage>
                    </MessageConteiner>
                  </>
                )}
                {item.author.type === 'customer' && (
                  <>
                    <ChatInfo side={'left'}>
                      <UserChatImg side={'left'} src={imgTest} />
                      <UserChatName switcher={switcher}>
                        {item.author.name}
                      </UserChatName>
                      <TimeChat side={'left'}>
                        {convertToHHMMSS(item.created_at)}
                      </TimeChat>
                    </ChatInfo>
                    <MessageConteiner side={'left'}>
                      <CustomerMessage>{item.text}</CustomerMessage>
                    </MessageConteiner>
                  </>
                )}
              </div>
            ))}
          </ChatConteiner>
        </>
      )}
    </SupportMenuConteiner>
  );
};

export default SupportMenu;
