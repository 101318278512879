import styled from 'styled-components';
import { PiTelegramLogo } from 'react-icons/pi';
import { IoShield } from 'react-icons/io5';

export const QaMessageAreaConteiner = styled.div`
  flex: 1;
  margin: 0 auto;
  justify-content: center;
  margin-left: 101px;
`;

export const MessageConteiner = styled.div`
  width: 1182px;
  height: 600px;
`;

export const Telegram = styled.img`
  color: #ffffff;
  width: 9px;
  height: 8px;
  margin-left: 5px;
`;

export const Shield = styled(IoShield)`
  color: #0a1019;
  width: 13px;
  height: 11px;
  margin-right: 5px;
`;

export const TextAreaMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 700px; /* Ширина контейнера */
`;

export const TextAreaQa = styled.textarea`
  width: 100%; /* Занимает всю ширину контейнера */
  height: 150px; /* Высота текстового поля */
  resize: none;
  background: #ffffff;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  padding: 15px;
  border-radius: 15px;
  border: none;
  outline: none;
  padding-bottom: 50px; /* Оставляем место для панели инструментов */
  box-sizing: border-box; /* Учитываем padding в ширине и высоте */

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;
`;

export const TextAreaToolsContainer = styled.div`
  position: absolute; /* Абсолютное позиционирование внутри обертки */
  bottom: 0; /* Расположить внизу контейнера */
  left: 0;
  width: 100%;
  height: 50px; /* Высота панели инструментов */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;
`;

export const TagBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const TagButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  color: #0a1019;
`;

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  background: #0a1019;
  color: #fff;
`;

export const ChatConteiner = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - 120px
  ); /* Убирает 60px от общей высоты экрана (например, высота шапки или подвала) */
  padding: 30px;
  border-right: 1px solid #dfdaeb;
  border-left: 1px solid #dfdaeb;
  overflow-y: auto;
`;

export const SystemMessage = styled.div`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  color: #b7b0c7;

  text-align: center;
  margin: 10px 0;
  width: 100%;
`;

export const AgentMessage = styled.div`
  background-color: #efe9ff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-end;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);

  width: 340px;
`;

export const CustomerMessage = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-start;
  width: 340px;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;
  border: 1px solid #f7f4fe;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
`;

export const ChatInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side === 'right' ? 'right' : 'left')};
  margin-top: 10px;
`;

export const UserChatImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 10px;
`;

export const UserChatName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #0a1019;

  padding: 0;
  margin: 0;
`;

export const TimeChat = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  margin-left: ${({ side }) => (side === 'right' ? '0' : '10px')};
  margin-right: ${({ side }) => (side === 'right' ? '10px' : '0')};
  margin-top: 0;
  margin-bottom: 0;

  color: #b7b0c7;
`;

export const MessageConteinerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
`;

export const MainCont = styled.div`
  display: flex;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
`;

export const MessageConatiner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
  margin: ${({ side }) => (side === 'right' ? '0 10px 0 0' : ' 0 0 0 10px')};
`;

export const MessageContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
`;

export const ImageContainer = styled.div``;

export const InputValueBlock = styled.div`
  width: 107px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  margin-bottom: -60px;
  justify-content: flex-start;
`;

export const InputContainer = styled.div`
  display: flex;
`;

export const ValueInput = styled.input`
  width: 45px;
  height: 23px;
  background: #ffffff;
  border: 1px solid #dfdaeb;
  border-radius: 15px;
  margin-top: 5px;
  margin-left: 5px;
  padding-left: 18px;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  outline: none;
  color: ${({ disabled }) => (disabled ? '#0A1019' : '#1EAF69')};
`;

export const ValueButton = styled.button`
  width: 27px;
  height: 27px;
  background: #f7f4fe;
  border-radius: 50px;
  border: 1px solid #dfdaeb;
  margin-top: 5px;
  margin-left: 3px;
  cursor: pointer;
  z-index: 5;
`;

export const GetChatButton = styled.button`
  width: 190px;
  height: 56px;
  background: #ffffff;
  border: 2px solid #dfdaeb;
  border-radius: 10px;
  margin: 200px auto 0;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #1eaf69;
  transition: border 0.3s ease, background 0.3s ease; /* Плавный переход */
  cursor: pointer;

  &:hover {
    border: 2px solid #1eaf69;
  }
`;

export const DateInput = styled.input`
  width: 130px;
  height: 10px;
  padding: 8px;
  border: 2px solid #dfdaeb;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  transition: border 0.3s ease;

  &:focus {
    border-color: #1eaf69;
    outline: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 50px;
`;
