import styled from 'styled-components';
import { RxAvatar } from 'react-icons/rx';
import { BsGeoAlt } from 'react-icons/bs';
import { FaRegClock } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { FaPlusSquare } from 'react-icons/fa';
import { FaSquareMinus } from 'react-icons/fa6';

export const GeneralInfoConteiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: calc(100vh - 120px);
  overflow-y: auto;
`;

export const GeneralInfoBlock = styled.div`
  margin-top: 19px;
  margin-left: 20px;
`;

export const TagsContainer = styled.div`
  width: 250px;
  height: 380px;
  overflow-y: auto;
`;

export const TagButton = styled.button`
  width: 100%;
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 5px;
  background: ${({ selected }) => (selected ? '#b2d8b2' : 'transparent')};
  color: black;
  cursor: pointer;
  border: 1px solid #dfdaeb;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: ${({ selected }) => (selected ? '#a2cfa2' : '#f0f0f0')};
  }
`;

export const UserBlock = styled.div``;

export const TextItem = styled.p`
  margin: 0;
  width: 271px;
`;

export const Labellll = styled.label`
  margin: 0;
  width: 240px;
`;

export const TextAreaaa = styled.textarea`
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 249px;
  height: 100px; // Фиксированная высота
  max-width: 300px;
  resize: none; // Отключаем изменение размера

  // Для предотвращения изменения размера на мобильных устройствах
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none; // Убираем контур фокуса
  }
`;

export const MainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  margin: 0;

  color: #0a1019;
`;

export const UserInfo = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const UserImg = styled.img``;

export const UserNameBlock = styled.div``;

export const UserName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  margin: 0;

  color: #0a1019;
`;

export const UserEmail = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;

  margin: 0;

  color: #0a1019;
`;

export const Avatar = styled(RxAvatar)`
  width: 30px;
  height: 30px;

  margin-right: 10px;
`;

export const TimeGeolocationBlock = styled.div``;

export const GeolocationItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const Geolocation = styled(BsGeoAlt)`
  width: 12px;
  height: 12px;

  margin-right: 8px;
`;

export const Time = styled(FaRegClock)`
  width: 12px;
  height: 12px;

  margin-right: 8px;
`;

export const TimeItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const MainDataBlock = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

export const MainDataList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const MainDataItem = styled.li``;

export const MainDataText = styled.p`
  width: 250px;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const MainDataSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #b7b0c7;
`;

export const PreChatFormBlock = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

export const PreChatFormList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const PreChatFormItem = styled.li``;

export const PreChatFormText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const PreChatFormSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #b7b0c7;
`;

export const TechnicalDataBlock = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

export const TechnicalDataList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const TechnicalDataItem = styled.li``;

export const TechnicalDataText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const TechnicalDataSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #b7b0c7;
`;

export const RequesterTicketsBlock = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

export const SecondMainText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const RequesterTicketsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const RequesterTicketsItem = styled.li`
  display: flex;
  align-items: center;
`;

export const ClosedBlock = styled.div`
  width: 43px;
  height: 10px;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 8px;
  text-align: center;

  color: #ffffff;

  background: #8a8a8a;
  border-radius: 2px;
  transform: matrix(1, 0, 0, -1, 0, 0);

  margin-right: 5px;
`;

export const LinkRequesterTickets = styled.a`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;

  color: #ae8cff;
`;

export const RequesterTicketsText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #b7b0c7;
`;

export const LocationItem = styled.div``;

export const ButtonAnalitics = styled.button`
  
  margin-top: 25px;
  width: 271px;
  border: 1px solid #dfdaeb;
  border-radius: 10px;
  background-color: ${({ des }) => (des ? '#fff' : 'rgb(253, 67, 67)')} ;
  padding: 10px;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #0a1019;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border 0.3s ease, background 0.3s ease; /* Плавный переход */

  &:hover {
    border:${({ des }) => (des ? '1px solid #1eaf69;' : '1px solid rgb(255, 0, 0)')} ; 
  }
`;

export const GenCon = styled.div`
  justify-content: flex-start;
  width: 300px;
`;

export const ArrowImgBot = styled(MdKeyboardArrowDown)`
  background-color: #f7f4fe;
  width: 18px;
  height: 18px;
  border-radius: 6px;
`;

export const ArrowImgTop = styled(MdKeyboardArrowUp)`
  background-color: #f7f4fe;
  width: 18px;
  height: 18px;
  border-radius: 6px;
`;

export const AnaliticBlock = styled.div`
  justify-content: flex-start;
`;

export const AnalyticsListGen = styled.ul`
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: auto;
  padding: 0;
`;

export const AnalyticsItemGen = styled.li`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  margin: 0 auto;

  color: #0a1019;

  width: 230px;
  display: flex;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const TextBlockGen = styled.div``;

export const PlusGen = styled(FaPlusSquare)`
  width: 11px;
  height: 11px;

  color: #1eaf69;
  cursor: pointer;
  margin-left: 5px;
  flex-shrink: 0;
`;

export const MinusGen = styled(FaSquareMinus)`
  width: 11px;
  height: 11px;
  cursor: pointer;

  color: #e12a2a;

  margin-left: 5px;
  flex-shrink: 0;
`;

export const CommentTextGen = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;

  margin: 10px 0 0 0;

  color: #0a1019;
`;

export const SendButton = styled.button`
  margin-top: 20px;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background: linear-gradient(135deg, #5a7bfa, #956bdc);
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const DateInfo = styled.div`
  margin: 0 auto;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #0a1019;
`;
