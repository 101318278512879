import styled from 'styled-components';

export const AgentStatMainConatiner = styled.div`
  padding: 94px 104px 0 183px;
`;

export const AgentStatMainText = styled.h2`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

export const AgentStatMainTextSpan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1eaf69;
`;

export const AgentStatInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

export const LeftInfoCon = styled.div`
  width: 567px;
  height: 250px;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
`;

export const LeftInfoTopCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 567px;
  height: 40px;
  left: 181px;
  top: 237px;

  background: #ae8cff;
  border-radius: 15px 15px 0px 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
`;

export const RightInfoTopCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 238px;
  height: 40px;
  left: 181px;
  top: 237px;

  background: #ae8cff;
  border-radius: 15px 15px 0px 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
`;

export const LeftInfoCenterCon = styled.div`
  display: flex;
  padding: 13px 10px 10px 10px;
  gap: 10px;
`;

export const LeftInfoConSmall = styled.div`
  width: 182px;
  height: 187px;

  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
`;

export const LeftInfoConSmallNumber = styled.p`
  margin: 36px 0 43px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 57px;
  text-align: center;

  color: #0a1019;
`;

export const LeftInfoConSmallNumberGreen = styled.p`
  margin: 36px 0 43px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 57px;
  text-align: center;

  color: #1eaf69;
`;

export const LeftInfoConSmallText = styled.p`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #b1b1b1;
`;

export const RightInfoCon = styled.div`
  width: 238px;
  height: 250px;

  border: 1px solid #d3d3d3;
  border-radius: 15px;
`;

export const RightInfoTopConItem = styled.div`
  display: flex;
  align-items: center;
`;

export const RightInfoCenterCon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 16px;
`;

export const RightInfoTopConText = styled.p`
  margin: 0;
  width: 183px;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0a1019;
  margin-left: 10px;
`;

export const GreenIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 4px;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 50px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

export const Th = styled.th`
  background: #a084e8;
  color: white;
  padding: 12px;
  text-align: center;
  font-weight: bold;
`;

export const Td = styled.td`
  padding: 12px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #333;
  min-height: 17px;
  text-align: center;
  &:last-child {
    border-right: 1px solid #ddd;
  }
`;

export const Tr = styled.tr`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  color: #000000;

  &:nth-child(even) {
    background: #f8f8f8;
  }
`;

export const Tag = styled.span`
  display: inline-block;
  background: #e0e0e0;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
`;

// Основной контейнер для пагинации
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
`;

// Кнопка пагинации
export const PaginationButton = styled.button`
  background-color: #ae8cff; /* Ваш основной цвет */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Состояние кнопки при отключении */
  &:disabled {
    background-color: #d1b6ff; /* Светлый оттенок для неактивной кнопки */
    cursor: not-allowed;
  }

  /* Состояние кнопки при наведении */
  &:hover {
    background-color: #7f5eff; /* Немного темнее оттенок для наведения */
  }

  /* Убираем обводку при фокусе */
  &:focus {
    outline: none;
  }
`;

// Включим другие стили для общего компонента, если нужно.
