import styled from 'styled-components';
import { FaPlusSquare } from 'react-icons/fa';
import { FaSquareMinus } from 'react-icons/fa6';
import { HiXMark } from 'react-icons/hi2';
import { GrPowerReset } from 'react-icons/gr';
import { MdOutlineDone } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';

export const SupportMenuConteiner = styled.div`
  background-color: ${props => (props.switcher ? '#2E2E2E' : '#fff')};
  width: 1100px;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;

  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
`;

export const TextInformationBlock = styled.div`
  height: 60px;
  margin-left: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;
`;

export const IdInformation = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  width: 80px;

  padding: 5px;

  color: #ffffff;

  background-color: #7ec7fb;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserGradeBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 30px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImg = styled.img``;

export const UserBlock = styled.div`
  margin-left: 7px;
`;

export const UserJobTitle = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;

  color: #717171;

  margin: 0;
`;

export const UserName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  margin-top: 5px;
  margin-bottom: 0;

  color: ${props => (props.switcher ? '#fff' : '#0a1019')};
`;

export const PercentBarBlock = styled.div`
  margin-left: 50px;
`;

export const PercentItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  margin: 2px;

  color: #717171;
`;

export const ProgressBar = styled.div`
  width: 220px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
`;

export const Progress = styled.div`
  width: ${props => props.percent}%;
  height: 10px;
  background-color: ${props =>
    props.percent > 90 ? 'green' : props.percent >= 80 ? 'yellow' : 'red'};
  transition: width 0.3s ease;
`;

export const ResultsBlock = styled.div`
  margin-left: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MistakeItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;

  color: #717171;
`;

export const CorrectItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;

  color: #717171;
`;

export const AnalyticsBlock = styled.div``;

export const AnalyticsMainText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 10px;
  margin-left: 30px;

  color: ${props => (props.switcher ? '#fff' : '#0a1019')};
`;

export const AnalyticsList = styled.ul`
  list-style: auto;
  margin-top: 0;
  height: 500px;
  padding: 15px 0 15px 40px;
  overflow-y: auto;
`;

export const AnalyticsItem = styled.li`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  color: ${props => (props.switcher ? '#fff' : '#0a1019')};

  cursor: pointer;

  width: 250px;
  display: flex;
  align-items: center;
`;

export const AnalyticsItemConatiner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 15px;
  padding: 5px 0; /* добавим немного отступов для эстетики */
  border-bottom: 1px solid ${props => (props.switcher ? '#fff' : '#dcdcdc')}; /* линия разделения */

  &:last-child {
    border-bottom: none; /* убираем линию у последнего элемента */
  }
`;

export const CommentText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;

  margin: 5px;
`;

export const CommentTextItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  width: 150px;
  margin: 5px;
  word-wrap: break-word; /* или */
  overflow-wrap: break-word;
`;

export const CommentTextItemTags = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  width: 200px;
  margin: 5px;
  word-wrap: break-word; /* или */
  overflow-wrap: break-word;
`;
export const TextBlock = styled.div`
  width: 220px;
`;

export const Plus = styled(FaPlusSquare)`
  width: 11px;
  height: 11px;

  color: #1eaf69;

  margin-left: 5px;
  flex-shrink: 0;
`;

export const Minus = styled(FaSquareMinus)`
  width: 11px;
  height: 11px;

  color: #e12a2a;

  margin-left: 5px;
  flex-shrink: 0;
`;

export const SwitchBar = styled.div`
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 10px;
`;

export const SwitchButton = styled.button`
  background-color: ${({ active }) => (active ? '#7ec7fb' : '#ffffff')};
  color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  border: ${({ active }) => (active ? 'none' : '1px solid #ccc')};
  border-radius: 5px;
  font-size: 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7ec7fb;
    color: #ffffff;
  }
`;

export const ChatConteiner = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 380px;
  overflow-y: auto;
  padding: 50px;
  border-radius: 10px;
  width: 900px;
  margin-left: 30px;
  margin-right: 30px;
`;

export const SystemMessage = styled.div`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;

  color: ${props => (props.switcher ? '#fff' : '#b7b0c7')};

  text-align: center;
  margin: 10px 0;
  width: 100%;
`;

export const AgentMessage = styled.div`
  background-color: #efe9ff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-end;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;

  width: 340px;
`;

export const CustomerMessage = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 70%;
  align-self: flex-start;

  font-family: 'Geologica';
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0a1019;
  border: 1px solid #f7f4fe;

  border: 1px solid #f7f4fe;
`;

export const ChatInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side === 'right' ? 'right' : 'left')};
  margin-top: 10px;

  position: relative;

  width: 100%;
`;

export const UserChatImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  right: ${({ side }) => (side === 'right' ? '-35px' : 'unset')};
  left: ${({ side }) => (side === 'right' ? 'unset' : '-35px')};

  position: absolute;
  bottom: -30px;
`;

export const UserChatName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: ${props => (props.switcher ? '#fff' : '#0a1019')};

  padding: 0;
  margin: 0;
`;

export const TimeChat = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  margin-left: ${({ side }) => (side === 'right' ? '0' : '10px')};
  margin-right: ${({ side }) => (side === 'right' ? '10px' : '0')};
  margin-top: 0;
  margin-bottom: 0;

  color: blask;
`;

export const MessageConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ side }) =>
    side === 'right' ? ' flex-end' : ' flex-start'};
`;

export const CreateAtTitle = styled.p`
  font-family: 'Geologica';
  font-size: 12px;
  margin: 0;
  text-align: center;
`;
export const CheckedBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Cross = styled(HiXMark)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-left: 10px;
`;

export const CheckedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: 10px;
  background: transparent;
  border: 1px solid #7ec7fb;
  border-radius: 30px;
  cursor: pointer;
`;

export const ResetIcon = styled(GrPowerReset)`
  color: #7ec7fb;
`;

export const DoneIcon = styled(MdOutlineDone)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  color: white;
  margin-left: 10px;
`;

export const DecisionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: 10px;
  background: transparent;
  border: 1px solid #7ec7fb;
  border-radius: 30px;
  cursor: pointer;
  width: 25.4px;
  height: 25.4px;
`;

export const DicisionIcon = styled(AiOutlineEdit)`
  color: #7ec7fb;
`;

export const List = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 200px;
  overflow-y: auto;
`;

export const TegText = styled.p`
  margin: 0;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
`;

export const Item = styled.div`
  padding: 10px;
  margin: 5px 0;
  background: ${props => (props.selected ? '#add8e6' : '#f0f0f0')};
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background: ${props => (props.selected ? '#87ceeb' : '#dcdcdc')};
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: white; /* Оставляем непрозрачным */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 500px;
  // display: flex;
  // flex-direction: column;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: gray;

  &:hover {
    color: black;
  }
`;

export const FormField = styled.div`
  margin-bottom: 15px;
`;

export const FormFieldd = styled.div`
  display: flex;
  flex-direction: row:
  margin-bottom: 15px;
`;

export const Labelll = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
`;

export const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;

  &:hover {
    background-color: #45a049;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 280px;
  height: 100px; // Фиксированная высота
  max-width: 300px;
  resize: none; // Отключаем изменение размера

  // Для предотвращения изменения размера на мобильных устройствах
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none; // Убираем контур фокуса
  }
`;
