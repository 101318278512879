import { createSlice } from '@reduxjs/toolkit';
import {
  getAllTegsThunk,
  getChatQaProjectsThunk,
  getChatQaRandomThunk,
  getChatqaResultStatisticsThunk,
  getLiveChatAgentIdThunk,
  getLiveChatAgentThunk,
  getQaInfoByAgentChatsThunk,
  getQaInfoByAgentThunk,
  patchQaChatChekedResultThunk,
  patchQaChatChekedThunk,
} from './qaActions';

const initialState = {
  chats: null,
  loading: null,
  error: null,
  agent: null,
  agentStat: null,
  allTags: null,
  infoAgent: null,
  infoAgentChats: null,
  randomChatInfo: null,
  projects: null,
};

const qaSlice = createSlice({
  name: 'qa',
  initialState,
  reducers: {
    clearRandomChatInfo: state => {
      state.randomChatInfo = null; // Сбрасываем randomChatInfo
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLiveChatAgentThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getLiveChatAgentThunk.fulfilled, (state, action) => {
        state.chats = action.payload;
        state.loading = false;
      })
      .addCase(getLiveChatAgentThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getLiveChatAgentIdThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getLiveChatAgentIdThunk.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.loading = false;
      })
      .addCase(getLiveChatAgentIdThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(patchQaChatChekedThunk.pending, state => {
        state.loading = true;
      })
      .addCase(patchQaChatChekedThunk.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.loading = false;
      })
      .addCase(patchQaChatChekedThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(patchQaChatChekedResultThunk.pending, state => {
        state.loading = true;
      })
      .addCase(patchQaChatChekedResultThunk.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.loading = false;
      })
      .addCase(patchQaChatChekedResultThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getChatqaResultStatisticsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getChatqaResultStatisticsThunk.fulfilled, (state, action) => {
        state.agentStat = action.payload;
        state.loading = false;
      })
      .addCase(getChatqaResultStatisticsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getAllTegsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getAllTegsThunk.fulfilled, (state, action) => {
        state.allTags = action.payload;
        state.loading = false;
      })
      .addCase(getAllTegsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getQaInfoByAgentThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getQaInfoByAgentThunk.fulfilled, (state, action) => {
        state.infoAgent = action.payload;
        state.loading = false;
      })
      .addCase(getQaInfoByAgentThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getQaInfoByAgentChatsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getQaInfoByAgentChatsThunk.fulfilled, (state, action) => {
        state.infoAgentChats = action.payload;
        state.loading = false;
      })
      .addCase(getQaInfoByAgentChatsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getChatQaRandomThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getChatQaRandomThunk.fulfilled, (state, action) => {
        state.randomChatInfo = action.payload;
        state.loading = false;
      })
      .addCase(getChatQaRandomThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getChatQaProjectsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getChatQaProjectsThunk.fulfilled, (state, action) => {
        state.projects = action.payload;
        state.loading = false;
      })
      .addCase(getChatQaProjectsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { clearRandomChatInfo } = qaSlice.actions;

export default qaSlice.reducer;
