import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectQaChatsAllTegs,
  selectQaChatsRandomInfo,
} from '../../../redux/selectors';
import {
  getAllTegsThunk,
  patchQaChatChekedResultThunk,
  patchQaChatChekedThunk,
} from '../../../redux/qa/qaActions';

import {
  AnaliticBlock,
  AnalyticsItemGen,
  AnalyticsListGen,
  ArrowImgBot,
  ArrowImgTop,
  ButtonAnalitics,
  MinusGen,
  PlusGen,
  TextBlockGen,
  TextItem,
  InfoContainer,
  GeneralInfoConteiner,
  TagsContainer,
  TagButton,
  SendButton,
} from './GeneralInfo.styled';
import { clearRandomChatInfo } from '../../../redux/qa/qaSlice';
import { Notify } from 'notiflix';

const GeneralInfo = ({ comment, setComment }) => {
  const dispatch = useDispatch();
  const chatInfo = useSelector(selectQaChatsRandomInfo);
  const token = useSelector(selectAccessToken);
  const allTegs = useSelector(selectQaChatsAllTegs);

  const [chatInfoEdit, setChatInfoEdit] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [formattedData, setFormattedData] = useState([]);
  const [formattedDataComment, setFormattedDataComment] = useState({
    checked: true,
    comment: '',
  });

  // Обновляем formattedDataComment при изменении comment
  useEffect(() => {
    setFormattedDataComment(prev => ({
      ...prev,
      comment: comment || '',
    }));
  }, [comment]);

  useEffect(() => {
    setChatInfoEdit(chatInfo ? JSON.parse(JSON.stringify(chatInfo)) : null);
  }, [chatInfo]);

  useEffect(() => {
    if (chatInfoEdit) {
      const data = Object.entries(chatInfoEdit.results).flatMap(
        ([agentName, agentData]) =>
          agentData.map(item => ({
            id: item.pk,
            decision: item.decision,
            tags: item.decision ? [] : (item.tags || []).map(tag => tag.id), // Достаем только id тегов
          }))
      );

      setFormattedData(data);
    }
  }, [chatInfoEdit]);

  const toggleWindow = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const toggleDecision = (agentName, index) => {
    setChatInfoEdit(prevChatInfoEdit => {
      if (!prevChatInfoEdit) return prevChatInfoEdit;

      const updatedChatInfo = JSON.parse(JSON.stringify(prevChatInfoEdit));
      updatedChatInfo.results[agentName][index].decision =
        !updatedChatInfo.results[agentName][index].decision;

      return updatedChatInfo;
    });
  };

  const getAllTegs = question => {
    dispatch(getAllTegsThunk({ token, question }));
  };

  const toggleTag = (agentName, questionIndex, tagId) => {
    setChatInfoEdit(prevChatInfoEdit => {
      if (!prevChatInfoEdit) return prevChatInfoEdit;

      const updatedChatInfo = JSON.parse(JSON.stringify(prevChatInfoEdit));
      const question = updatedChatInfo.results[agentName][questionIndex];

      const tagIds = question.tags.map(tag => tag.id); // Достаем id из объектов

      if (!tagIds.includes(tagId)) {
        question.tags.push({ id: tagId }); // Добавляем объект с id
      } else {
        question.tags = question.tags.filter(tag => tag.id !== tagId);
      }

      return updatedChatInfo;
    });
  };

  const sendEdits = async () => {
    try {
      const formattedPayload = formattedData.map(({ id, decision, tags }) => ({
        id,
        decision,
        tags,
      }));

      // console.log('Final payload:', formattedPayload);
      // console.log(JSON.stringify(formattedPayload));

      await dispatch(
        patchQaChatChekedResultThunk({
          token,
          formData: formattedPayload,
        })
      );

      await dispatch(
        patchQaChatChekedThunk({
          token,
          formData: formattedDataComment,
          id: chatInfoEdit.pk,
        })
      );

      Notify.success('Чат проверен');
    } catch (error) {
      Notify.failure('Произошла ошибка');
    }

    dispatch(clearRandomChatInfo());
    setComment('');
  };

  return chatInfoEdit ? (
    <GeneralInfoConteiner>
      {Object.entries(chatInfoEdit?.results).map(([agentName, agentData]) => (
        <div key={agentName}>
          <h3 style={{ margin: '10px 0 0 0' }}>{agentName}</h3>
          {agentData.map((item, index) => (
            <InfoContainer
              key={item.pk}
              onClick={() => getAllTegs(item?.question)}
            >
              {console.log()}
              <ButtonAnalitics des={item?.decision}
                onClick={() => toggleWindow(`${agentName}-${index}`)}
              >
                <TextItem>{item?.question || `Вопрос ${index + 1}`}</TextItem>
                {openIndex === `${agentName}-${index}` ? (
                  <ArrowImgTop />
                ) : (
                  <ArrowImgBot />
                )}
              </ButtonAnalitics>

              {openIndex === `${agentName}-${index}` && (
                <AnaliticBlock>
                  <AnalyticsListGen>
                    {item.decision ? (
                      <PlusGen
                        onClick={() => toggleDecision(agentName, index)}
                      />
                    ) : (
                      <MinusGen
                        onClick={() => toggleDecision(agentName, index)}
                      />
                    )}

                    <AnalyticsItemGen>
                      <TextBlockGen>
                        {item?.explanation || 'No explanation provided'}
                      </TextBlockGen>
                    </AnalyticsItemGen>
                  </AnalyticsListGen>
                  {!item.decision && allTegs?.length > 0 && (
                    <TagsContainer>
                      <h4>Выберите теги:</h4>
                      {allTegs.map(tag => (
                        <TagButton
                          key={tag.id}
                          onClick={() => toggleTag(agentName, index, tag.id)}
                          selected={item.tags?.map(t => t.id).includes(tag.id)}
                        >
                          {tag.name}
                        </TagButton>
                      ))}
                    </TagsContainer>
                  )}
                </AnaliticBlock>
              )}
            </InfoContainer>
          ))}
        </div>
      ))}

      <SendButton onClick={sendEdits}>Отправить</SendButton>
    </GeneralInfoConteiner>
  ) : null;
};

export default GeneralInfo;
{
  /* <ButtonAnalitics onClick={() => setAnother(general, setGeneral)}>
        General info
        {general === true ? <ArrowImgTop /> : <ArrowImgBot />}
      </ButtonAnalitics>

      {general === true ? (
        <GenCon>
          <GeneralInfoBlock>
            <MainText>General info</MainText>
            <UserBlock>
              <UserInfo>
                <UserImg />
                <Avatar />
                <UserNameBlock>
                  <UserName>Артём Ковальцев</UserName>
                  <UserEmail>artemkov982@gmail.com</UserEmail>
                </UserNameBlock>
              </UserInfo>
            </UserBlock>
            <TimeGeolocationBlock>
              <GeolocationItem>
                <Geolocation />
                Kazan, Tatarstan People’s Republic
              </GeolocationItem>
              <TimeItem>
                <Time />
                21:43 user local time
              </TimeItem>
              <LocationItem>
                <Map coordinates={testLocation} />
              </LocationItem>
            </TimeGeolocationBlock>
          </GeneralInfoBlock>
          <MainDataBlock>
            <MainText>Main data</MainText>
            <MainDataList>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Device:</MainDataSpan> Mobile
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Language:</MainDataSpan> Ru
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Loyalty Level:</MainDataSpan> 4-Pro
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Name:</MainDataSpan> Артём
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Surname:</MainDataSpan> Ковальцев
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>e-mail:</MainDataSpan> artemkov982@gmail.com
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Project:</MainDataSpan> Gama
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Site:</MainDataSpan> https://gamacasino666.com
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>UserID:</MainDataSpan>{' '}
                  https://www2.fundist.org/ru/Users/Summary/589385128
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Tag:</MainDataSpan> PriVip
                </MainDataText>
              </MainDataItem>
            </MainDataList>
          </MainDataBlock>
          <PreChatFormBlock>
            <MainText>Pre-chat form</MainText>
            <PreChatFormList>
              <PreChatFormItem>
                <PreChatFormText>
                  <PreChatFormSpan>Name:</PreChatFormSpan> Артём Ковальцев
                </PreChatFormText>
              </PreChatFormItem>
              <PreChatFormItem>
                <PreChatFormText>
                  <PreChatFormSpan>e-mail:</PreChatFormSpan>{' '}
                  artemkov982@gmail.com
                </PreChatFormText>
              </PreChatFormItem>
            </PreChatFormList>
          </PreChatFormBlock>
          <TechnicalDataBlock>
            <MainText>Technical data</MainText>
            <TechnicalDataList>
              <TechnicalDataItem>
                <TechnicalDataText>
                  <TechnicalDataSpan>IP:</TechnicalDataSpan> 173.121.65.9
                </TechnicalDataText>
              </TechnicalDataItem>
              <TechnicalDataItem>
                <TechnicalDataText>
                  <TechnicalDataSpan>OS/Device:</TechnicalDataSpan> iOS (17.2.1)
                </TechnicalDataText>
              </TechnicalDataItem>
              <TechnicalDataItem>
                <TechnicalDataText>
                  <TechnicalDataSpan>Browser:</TechnicalDataSpan> Mobile Safari
                  (17.2)
                </TechnicalDataText>
              </TechnicalDataItem>
            </TechnicalDataList>
          </TechnicalDataBlock>
          <RequesterTicketsBlock>
            <MainText>Requester tickets</MainText>
            <SecondMainText>Recent tickets</SecondMainText>
            <RequesterTicketsList>
              <RequesterTicketsItem>
                <ClosedBlock>CLOSED</ClosedBlock>
                <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
              </RequesterTicketsItem>
              <RequesterTicketsItem>
                <ClosedBlock>CLOSED</ClosedBlock>
                <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
              </RequesterTicketsItem>
              <RequesterTicketsItem>
                <ClosedBlock>CLOSED</ClosedBlock>
                <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
              </RequesterTicketsItem>
            </RequesterTicketsList>
            <SecondMainText>Archived tickets</SecondMainText>
            <RequesterTicketsText>
              <LinkRequesterTickets>Search Archive</LinkRequesterTickets> to
              view this requester’s archived tickets.
            </RequesterTicketsText>
          </RequesterTicketsBlock>
        </GenCon>
      ) : (
        <></>
      )} */
}
